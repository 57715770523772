import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function UserLogin() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage]= useState('');
  
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {

    setIsLoading(true);

    const formData = {
      username: username,
      password: password
    }

    const handleLogout = () => {
      // Log the user out by clearing the user_id from localStorage
      localStorage.removeItem('user_id');
      localStorage.removeItem('church_id');

      // Redirect to the login page
      navigate('/login');
    }


    try {

       // Define the apiBaseUrl based on your server environment
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost"; // You can adjust the default value as needed

      console.log("BEY!", apiBaseUrl);

      const response = await axios.post(`${apiBaseUrl}/v2r-web-apis/api/login.php`, formData);

      if (response.data.success) {
        console.log('Login successful');
        console.log(response.data);
        setMessage(response.data.success);

         // Store the user ID and Church ID in localStorage upon successful login
         localStorage.setItem('user_id', response.data.user_id);
         localStorage.setItem('church_id', response.data.church_id);

        setTimeout(()=>{  
          navigate('/');
        }, 2000);

        
      } else {
        setMessage('Invalid username or password');
        console.log(message)
        console.log(response.data);
      }
    } catch (message) {
      console.error('An error occurred:', message);
      setMessage('An error occurred while logging in');
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = username.trim() !== '' && password.trim() !== '';

  return (
    <div className="container mt-4">
      <h2>User Login</h2>
      <p className="text-success"> { message }</p> 
      <div className="mb-3">
        <label className="form-label">Username</label>
        <input
          type="text"
          className="form-control"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <button
          className="btn btn-success"
          onClick={handleLogin}
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? 'Logging In...' : 'Login'}
        </button>
      </div>
      {message && <p className="text-danger">{message}</p>}
    </div>
  );
}

export default UserLogin;
