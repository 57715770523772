import React from "react";
// import { useNavigate } from "react-router-dom";




function AddVerse() {

    // const navigate = useNavigate(); 
  return (
    <React.Fragment>
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-4 text-start mt-4">
                    <h2>Add Verse</h2>
                    <p className="">&copy; 2023. Verses to Remember</p>
                </div>
                <div className="col-md-8 text-start mt-4">
                    <p className="">Verses to Remember</p>
                </div>
            </div>
        </div>      
    </React.Fragment>
  );
}

export default AddVerse;