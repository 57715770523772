import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ChurchSelector = ({ onSelectChurch }) => {
  const [churches, setChurches] = useState([]);
  const [selectedChurch, setSelectedChurch] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost"; // You can adjust the default value as needed


  // Fetch the list of churches from the server
  useEffect(() => {
    axios.get(`${apiBaseUrl}/v2r-web-apis/api/get-churches.php`)
      .then(response => {
        setChurches(response.data);
      })
      .catch(error => {
        console.error('Error fetching churches:', error);
      });
  }, []);

// Handle church selection
const handleChurchSelect = (event) => {
    const churchId = event.target.value; // Get the selected value from the event
   // const church = churches.find(church => church.church_id === parseInt(churchId)); // Parse the ID to integer
    setSelectedChurch(churchId);
    onSelectChurch(churchId); // Pass selected church to parent component
    //console.log("the selected church", selectedChurch)
    console.log("Selected church ID:", churchId); // Log the ID of the selected church

    
  };

  return (
    <div>
      <h2>Select a Church</h2>
      <select onChange={handleChurchSelect}>
        <option value="">Select a church</option>
        {churches.map(church => (
          <option key={church.church_id} value={church.church_id}>
            {church.church_name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ChurchSelector;
