import './Style.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Header from './Component/Header';
import Footer from './Component/Footer';

import Userlist from './Component/Userlist';
import UserLogin from './Component/UserLogin';
import UserSignup from './Component/UserSignup';


// import Home from './Component/Home';
import Verseslist from './Component/Verseslist';
import ViewActiveChurchVerse from './Component/ViewActiveChurchVerse';

import AddVerse from './Component/AddVerse';
import EditVerse from './Component/EditVerse';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if the user is already logged in..
  useEffect(() => {
    const storedToken = localStorage.getItem('user_id'); // ( Check for an authentication token )
    if (storedToken) {
      setIsLoggedIn(true);
    }
  }, []);

  // If not, check function to handle successful login and set login state...
  const handleSuccessfulLogin = () => {
    setIsLoggedIn(true);
  };

  // When user is ready to log out, here's the function to handle logout..
  const handleLogout = () => {
    // Remember to clear the user token or session data from local storage...
    localStorage.removeItem('user_id');
    setIsLoggedIn(false);
  };

  // Render application components ...
  return (
    <div className="App">
      <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Navigate to="app/study/today/:church_id" />} /> {/* Redirect / to /study/today as the base URL users will come to*/}
        <Route path="/userlist" element={<Userlist />} />
        
        {/* Allow access to the study/today route regardless of login (base view). This is controlled by which church is registered. */}
        <Route path="app/study/:verse_month/:church_id" element={<ViewActiveChurchVerse />} />

        {/* If the user is logged in ... */}
        {isLoggedIn ? (
          // Show Protected routes for authenticated users...
          <>
            <Route path="/verseslist" element={<Verseslist />} />
            <Route path="/addverse" element={<AddVerse />} />
            <Route path="/editverse/:id" element={<EditVerse />} />
          </>
        ) : (
          // Public routes for non-authenticated users
          <>
            <Route
              path="/login"
              element={
                <UserLogin
                  onLogin={handleSuccessfulLogin}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
            <Route path="/usersignup" element={<UserSignup />} />
          </>
        )}

        {/* Include a fallback route for unmatched paths (may not need) */}
        <Route path="*" element={<Navigate to="app/study/today/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
