import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import DOMPurify from "dompurify"; // Import DOMPurify
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function ViewActiveChurchVerse() {
  const [activeChurchVerses, setActiveChurchVerses] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState("ESV");
  const [churchData, setChurchData] = useState(null);
  const { verse_month, church_id } = useParams();

  // Function to convert month number to nice month name
  const niceMonthfromNumber = (monthNumber) => {
    const niceMonthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return niceMonthNames[monthNumber - 1] || "Unknown";
  };

  // Function to get the month number from the URL or use the current month as a default
  const getMonthNumberFromURL = () => {
    if (verse_month && verse_month !== "today") {
      return parseInt(verse_month, 10);
    } else {
      const currentDate = new Date();
      return currentDate.getMonth() + 1;
    }
  };

  // Depending on the Number of the Month (e.g 1), get the Nice Month Name (e.g January)
  const niceMonthName = niceMonthfromNumber(getMonthNumberFromURL());

  // Determine the base URL dynamically based on the host and protocol
  let apiBaseUrl = '';

  if (window.location.hostname === "localhost") {
    // Set apiBaseUrl for local development
    apiBaseUrl = process.env.REACT_APP_API_BASE_URL_DEV; // Update with your local development URL
  } else if (window.location.hostname === "versestoremember.com") {
    // Set apiBaseUrl for production
    apiBaseUrl = process.env.REACT_APP_API_BASE_URL_PROD; // Update with your production URL
  }

  console.log(`API Base URL: ${apiBaseUrl}`);

  // Function to fetch verses based on the selected version
  const getActiveChurchVersesData = async (version) => {
    try {
      const apiPath = `/get-active-church-verses.php?verse_month=${getMonthNumberFromURL()}&church_id=${church_id}&version=${version}`;
      const apiString = `${apiBaseUrl}${apiPath}`;
      console.log("This is the full string: " + apiString);
      const response = await fetch(apiString);
      const data = await response.json();
      // Sanitize the HTML content from the API response
      const sanitizedHtmlContent = DOMPurify.sanitize(data[`verse_content_${version.toLowerCase()}`]);
      // Set the sanitized content in the state
      data[`verse_content_${version.toLowerCase()}`] = sanitizedHtmlContent;
      setActiveChurchVerses(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchChurchData = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/get-church-data.php?church_id=${church_id}`);
        const churchDataArray = await response.json();
        if (churchDataArray.length > 0) {
          const churchData = churchDataArray[0]; // Access the first object in the array
          setChurchData(churchData);
          console.log(churchData);
          console.log("show_esv:", churchData.show_esv);
          console.log("show_kjv:", churchData.show_kjv);
          console.log("show_hcv:", churchData.show_hcv);
        }
      } catch (error) {
        console.error("Error fetching church data:", error);
      }
    };

    fetchChurchData();
  }, [church_id]);

  useEffect(() => {
    getActiveChurchVersesData(selectedVersion);
  }, [church_id, verse_month, selectedVersion, churchData]);

  // Function to handle tab selection
  const handleTabClick = (version) => {
    setSelectedVersion(version);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-12 text-start mt-4">
          <h2>Verse of The Month</h2>
          <h3>{niceMonthName}</h3> 
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 text-start mt-4">
          <div className="tab">
            {churchData && Number(churchData.show_esv) === 1 && (
              <button className={selectedVersion === "ESV" ? "btn tablinks active" : "btn tablinks"} onClick={() => handleTabClick("ESV")}>ESV</button>
            )}
            {churchData && Number(churchData.show_kjv) === 1 && (
              <button className={selectedVersion === "KJV" ? "btn tablinks active" : "btn tablinks"} onClick={() => handleTabClick("KJV")}>KJV</button>
            )}
            {churchData && Number(churchData.show_hcv) === 1 && (
              <button className={selectedVersion === "HCV" ? "btn tablinks active" : "btn tablinks"} onClick={() => handleTabClick("HCV")}>HCV</button>
            )}
          </div>
          &nbsp;
          {activeChurchVerses ? (
            <div>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {activeChurchVerses.map((verse, index) => (
                  <SwiperSlide key={index}>
                    <p className="active-verse-title">{verse.verse_title}</p>
                    <p className="active-verse-reference">
                      {verse.verse_bible_reference} ({selectedVersion})
                    </p>
                    <div
                      className="active-verse-content"
                      dangerouslySetInnerHTML={{ __html: verse[`verse_content_${selectedVersion.toLowerCase()}`] }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (
            <p>Loading...</p>
          )}
          &nbsp;
          <p>( swipe to view verses for the previous months )</p>
        </div>
        <div className="col-md-4 text-start mt-4">
          <div className="active-verse-qr-code">
          <QRCode value={`${window.location.href}`} />
            {/* <QRCode value={`${apiBaseUrl}/study/${verse_month}/${church_id}`} /> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="empty-space-filler"></div>
      </div>
    </div>
  );
}

export default ViewActiveChurchVerse;
