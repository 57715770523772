import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function UserSignup() {
    
    const navigate = useNavigate();      
    const [formvalue, setFormValue] = useState({username: "", user_email: "", user_password: ""});
    const [message, setMessage]= useState('');

    const handleInput = (e) => {
        const {name, value} = e.target;
        setFormValue({...formvalue, [name]: value});
    }

    const handleSubmit = async(e) => {

        e.preventDefault();

        console.log(formvalue);

        const formData = { username: formvalue.username, user_email: formvalue.user_email, user_password: formvalue.user_password }
        const res = await axios.post('http://localhost/v2r-web-apis/api/user.php', formData);

        console.log(res);

        if(res.data.success){
            console.log("What it do!"); 
         setMessage(res.data.success);
         setTimeout(()=>{          
            navigate('/');
            console.log("Baby boo!");    
         }, 4000);

        }
        


        // fetch("http://localhost/v2r-web-app/api/user.php", {
        // fetch("http://localhost:3000/api/user/signup", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(formvalue),
        // })
        // .then((response) => response.json())
        // .then((result) => {
        //     console.log("Success:", result);
        //     setFormValue({username: "", email: "", password: ""});
        // })
        // .catch((error) => {
        //     console.error("Error:", error);
        // });
    }

  return (
    <React.Fragment>
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-12 text-start mt-4">
                    <h2>User Signup</h2>
                    <p className="text-success"> { message }</p>   
                    <form onSubmit={ handleSubmit }>
                        <div className="mb-3 row">
                            <label className="col-sm-2 col-form-label">Username</label>
                            <div className="col-sm-10">
                            <input type="text" name="username" value={formvalue.username} className="form-control" id="userName" onChange={ handleInput } />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-2 col-form-label">Email</label>
                            <div className="col-sm-10">
                            <input type="text" name="user_email" value={formvalue.user_useremail} className="form-control" id="userEmail" onChange={ handleInput }  />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-2 col-form-label">Password</label>
                            <div className="col-sm-10">
                            <input type="password" name="user_password" value={formvalue.user_password} className="form-control" id="inputPassword" onChange={ handleInput }  />
                            <p className=""></p>
                            <button type="submit" className="btn btn-success">Sign up</button>
                            </div>
                        </div>
                        
                    </form>
                    
                </div>
            </div>
        </div>      
    </React.Fragment>
  );
}

export default UserSignup;