import React from "react";


function Footer() {
  return (
    <React.Fragment>
        <footer className="footer bg-dark fixed-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <p className="text-white text-center">&copy; 2024. Verses to Remember. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>   
    </React.Fragment>
  );
}

export default Footer;