import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function EditVerse() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [verses, setVerses] = useState([]);
  const [formvalue, setFormValue] = useState({
    verse_title: "",
    verse_content_esv: "",
    verse_content_kjv: "",
    verse_video_source: "",
  });
  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formvalue, [name]: value });
  };

  // Define the apiBaseUrl based on your server environment
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost"; // You can adjust the default value as needed

  useEffect(() => {
    const getVerseData = async () => {
      try {
        const requestData = await fetch(`${apiBaseUrl}/v2r-web-apis/api/verses.php/${id}`);
        const responseData = await requestData.json();
        setFormValue(responseData);
        setVerses(responseData);
      } catch (error) {
        console.error("Error fetching verse data:", error);
      }
    };
    getVerseData();
  }, [id, apiBaseUrl]); // Make sure to include apiBaseUrl as a dependency

  const handleSubmit = async (e) => {
    e.preventDefault();

    const verseData = {
      id: id, // Use the specific ID
      verse_title: formvalue.verse_title,
      verse_content_esv: formvalue.verse_content_esv,
      verse_content_kjv: formvalue.verse_content_kjv,
      verse_video_source: formvalue.verse_video_source,
    };

    try {
      // Update the verse
      const verseRes = await axios.put(`${apiBaseUrl}/v2r-web-apis/api/verses.php`, verseData);

      if (verseRes.data.success) {
        const scheduleData = {
          verse_id: id, // Use the specific ID
          // Include other schedule data here
        };

        // Update the schedule
        const scheduleRes = await axios.put(`${apiBaseUrl}/v2r-web-apis/api/schedule.php`, scheduleData);

        const datesData = {
          verse_id: id, // Use the specific ID
          // Include other date data here
        };

        // Update the dates
        const datesRes = await axios.put(`${apiBaseUrl}/v2r-web-apis/api/dates.php`, datesData);

        if (scheduleRes.data.success && datesRes.data.success) {
          setMessage("Verse and schedule updated successfully");
          setTimeout(() => {
            navigate("/verseslist");
          }, 2000);
        } else {
          setMessage("Failed to update schedule or dates");
        }
      } else {
        setMessage("Failed to update verse");
      }
    } catch (error) {
      console.error("Error updating verse:", error);
      setMessage("An error occurred while updating the verse");
    }
  };

  return (
    <div className="container mt-4">
      {/* The rest of your component code remains the same */}
    </div>
  );
}

export default EditVerse;
