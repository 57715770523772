import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ChurchSelector from './ChurchSelector';


function Verseslist({ churchId }) {


  // ===============================
  // [ Where Constants Start ]
  // ===============================

  const [verses, setVerses] = useState([]); // [ Main state storage for Verses that will populate the list for review ]
  const [message, setMessage] = useState(""); // [ Logs error messages (for User to Review Success or Failure)]
  const [selectedCollectionYear, setSelectedCollectionYear] = useState("Year 1"); // [ Sets Collection in Review ]
  const [selectedChurch, setSelectedChurch] = useState(null);

  const [verseCollections, setVerseCollections] = useState([]);
  
  
  // The Full Start/End Date of the Collection
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  

  // The Active/Inactive Status of a given Collection
  const [isActive, setIsActive] = useState(false);
  
  // The Array Structure for the Module Dates for each module in the collection
  const [moduleDates, setModuleDates] = useState(Array(12).fill(null));
  


  const [masterSchedule, setMasterSchedule] = useState(null); // Store the master schedule window
  const filteredVerses = verses.filter((verse) => verse.verse_collection_group === selectedCollectionYear);
  
  // ===============================


  // Define the apiBaseUrl based on your server environment
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost"; // You can adjust the default value as needed

  // ===============================
  // [ Main Initiation Function ]
    // ===============================

  useEffect(() => { getVersesData(); getVerseCollectionsData(); }, [selectedCollectionYear, verseCollections, churchId]);
  // ===============================


  // ===============================
  // [ Get Verses Data - Displays all the verse on the page ]
  // ===============================
  const getVersesData = async () => {
    const requestData = await fetch(`${apiBaseUrl}/v2r-web-apis/api/verses.php`);
    const responseData = await requestData.json();
    console.log(responseData);
    setVerses(responseData);
  };

    // ===============================


    const getVerseCollectionsData = async () => {
      const requestData = await fetch(`${apiBaseUrl}/v2r-web-apis/api/verse-collections.php`);
      const responseData = await requestData.json();
      console.log("Verses Collections", responseData);
      setVerseCollections(responseData);
    };


    



// ===============================
// [ Handle Deleting of Verse (Currently Disabled ) ]
// ===============================

  const handleDelete = async (id) => {
    const res = await axios.delete(`${apiBaseUrl}/v2r-web-apis/api/verses.php/` + id);
    setMessage(res.data.success);
    getVersesData();
  };

    // ===============================

  // ===============================
  // Module to Generate the Proposed Schedule for the Collection
    // ===============================

  const handleGenerateSchedule = () => {
      // The modules that act as the spacing out between the months based on the date set for study.
      const numModules = 12;
      const timeDifference = endDate.getTime() - startDate.getTime();
      const interval = timeDifference / numModules;

      const newScheduledDates = Array.from({ length: numModules }, (_, index) => {
        return new Date(startDate.getTime() + index * interval);
      });

      // Save module dates in array to be processed later. When these get saved, there's also a sister function that populates the form fields with the dates.
      setModuleDates(newScheduledDates);

      console.log(newScheduledDates);
  };

    // ===============================

    const handleChurchSelect = (churchId) => {
      console.log("Selected church ID:", churchId);
      setSelectedChurch(churchId);
      // Implement any logic related to the selected church
    };

  // ===============================
  // The actual module to save the schedule (populating each of the row with the supposed date.)
  // ===============================


  const handleSaveSchedule = async () => {
    try {
      const data = {
        church_id: selectedChurch,
        verse_collection_name: selectedCollectionYear,
        verse_collection_study_start: startDate,
        verse_collection_study_end: endDate,
        verse_collection_active: isActive,
        verse_module_dates: filteredVerses.map((verse, index) => ({
          id: verse.id,
          verse_study_date: moduleDates[index],
        })),
      };
  
      // Make a POST request to save the schedule
      // const response = await axios.post(`${apiBaseUrl}/v2r-web-apis/api/verse-collections.php`, data);
      const response = await axios.post(`${apiBaseUrl}/v2r-web-apis/api/save-verse-selections.php`, data);
      // console.log("Response Data:", response.data);
      // console.log("Response Data Success:", response.data[0].success);
  
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Check the response data for success message
        // if (response.data[0].successs) {
          setMessage("Schedule saved successfully.");
        // } else {
        //   setMessage("Failed to save schedule.");
        // }
      } else {
        setMessage("Failed to save schedule. Server returned an error.");
      }
    } catch (error) {
      // Handle errors that occur during the request
      if (error.response) {
        // The request was made, but the server responded with an error status code
        setMessage(`Server Error: ${error.response.data}`);
      } else if (error.request) {
        // The request was made, but no response was received
        setMessage("Request Error: No response received.");
      } else {
        // Something else happened while setting up the request
        setMessage(`Error: ${error.message}`);
      }
    }
  };
  
    // ===============================



  // ===============================
  // This "Switch" is to make the collection active or inactive for study. This translates to it being "Open" or "Closed" on the frontend.
  // ===============================

  const handleSwitchCollection = (collection, isActive) => {

        if (isActive) {
          console.log("Collection is active");
        } else {
          console.log("Collection is inactive");
        }
        // Implement activation/deactivation logic

  };

  // ===============================



  // ================================================================
  // [ RENDER ON THE FRONTEND ]
  // ================================================================

  return (
    <React.Fragment>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-3 text-start mt-4">
            <h2>Verses</h2>
            <p>Verse Collections</p>
            <p>
              <button className="btn" onClick={() => setSelectedCollectionYear("Year 1")}>Year 1</button>
            </p>
            <p>
              <button className="btn" onClick={() => setSelectedCollectionYear("Year 2")}>Year 2</button>
            </p>
            <p>
              <button className="btn" onClick={() => setSelectedCollectionYear("Year 3")}>Year 3</button>
            </p>
            <p>
              <button className="btn" onClick={() => setSelectedCollectionYear("Year 4")}>Year 4</button>
            </p>
          </div>
          <div className="col-md-9 text-start mt-4">
            <h1>Editing Verse Collection</h1>
            <ChurchSelector  onSelectChurch={handleChurchSelect} />

            {/* <p>Collection: {selectedCollectionYear}</p>
            <p>Start Date: {startDate ? startDate.toLocaleDateString() : ""}</p>
            <p>End Date: {endDate ? endDate.toLocaleDateString() : ""}</p>
            <p>Active: {isActive ? "Yes" : "No"}</p> */}
            {/* ======================================================= */}
            {/* ============[ SCHEDULE FORM ] ============ */}
            {/* ======================================================= */}
            <div>
              {/* ======================================================= */}
              {/* ============[ GENERATE SCHEDULE FOR MODULE ============ */}
              {/* ======================================================= */}
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  isClearable
                  placeholderText="Start Date"
                />
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  isClearable
                  placeholderText="End Date"
                />
                <button className="btn btn-secondary" onClick={handleGenerateSchedule}>Generate Schedule</button>
              </div>
              {/* ======================================================= */}

              {/* ======================================================= */}
              {/* ============[ MAKE MODULE ACTIVE/INACTIVE BASED ON STUDY ] ============ */}
              {/* ======================================================= */}
              <label>
                Collection Status: &nbsp;
                <input type="checkbox" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
              </label>
            </div>
            <button className="btn btn-secondary" onClick={handleSaveSchedule}>Save Schedule</button>
            <p className="text-danger">{message}</p>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Verse Reference</th>
                  <th scope="col">Verse Title</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredVerses.map((verse, index) => (
                  <tr key={verse.id}>
                    <td>{verse.id}</td>
                    <td dataVerseId={verse.id}>{verse.verse_bible_reference}</td>
                    <td>{verse.verse_title}</td>
                    <td>
                      <Link to={"/editverse/" + verse.id} className="btn btn-success">
                        Edit
                      </Link>
                      &nbsp;
                      <DatePicker
                        selected={moduleDates[index]}
                        onChange={date => {
                          const updatedDates = [...moduleDates];
                          updatedDates[index] = date;
                          setModuleDates(updatedDates);
                        }}
                        placeholderText="Module Date"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  // =====================================================================================
  // [ End of Verseslist Component ]
  // =====================================================================================
}

export default Verseslist;
