import './../Style.css';
import v2rLogo from './../assets/imgs/V2R-logo-web-bw.png';
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

function Header({ isLoggedIn }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Log the user out by clearing the user_id from localStorage
    localStorage.removeItem('user_id');

    // Redirect to the login page
    navigate('/login');
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg v2r-header-bg">
        <div className="container">
          <NavLink to="/" className="navbar-brand">
          <img className="v2r-logo" src={v2rLogo} alt="Verses To Remember Logo"></img>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" aria-current="page">
                  Home
                </NavLink>
              </li>
              {isLoggedIn && ( // Render "Verses" link only when logged in
                <li className="nav-item">
                  <NavLink to="/verseslist" className="nav-link" aria-current="page">
                    Verses
                  </NavLink>
                </li>
              )}
              {isLoggedIn && ( // Render "User List" link only when logged in
                <li className="nav-item">
                  <NavLink to="/userlist" className="nav-link" aria-current="page">
                    User
                  </NavLink>
                </li>
              )}
              {isLoggedIn && ( // Render "User List" link only when logged in
              <li className="nav-item">
                <NavLink to="/usersignup" className="nav-link" aria-current="page">
                  User Signup
                </NavLink>
              </li>
              )}
            </ul>
            <div className="d-flex">
              <button
                className={`btn ${isLoggedIn ? 'btn-danger' : 'btn-success'} ms-3`}
                onClick={isLoggedIn ? handleLogout : () => navigate('/login')}
              >
                {isLoggedIn ? 'Log Out' : 'Log In'}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default Header;
